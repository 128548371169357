import { BlockerTextContainer, useBlocker } from "../..";

const Blocker = () => {
  const blocker = useBlocker();
  const {
    blocker: {
      visualType,
      visualThumbnail
    }
  } = blocker;

  if (process.env.PLUGIN_CTX === "pro") {
    /* Silence is golden... Until you have PRO Version! */
  } else {
    return h(BlockerTextContainer, null);
  }
};

export { Blocker };