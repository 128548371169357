import { parents, isElementInViewport, HTML_ATTRIBUTE_BLOCKER_CONNECTED, HTML_ATTRIBUTE_WAS_PREVIOUSLY_STRICT_HIDDEN, HTML_ATTRIBUTE_BLOCKER_CONNECTED_PRESERVED, HTML_ATTRIBUTE_VISUAL_PAINT_MODE } from "..";
import { probablyResetParentContainerForVisual, isPotentialVisualWithinResponsiveContainer, findVisualParent, findPreviousVisibleDuplicate, createVisualContainer } from ".";
/**
 * Save the identifier for the last clicked content blocker so we can
 * dispatch in our event if it is the clicked one. This is useful e.g.
 * to delegate the click event further and start a video.
 */

let lastClickedConnectedCounter = undefined;
let connectedCounterPreserve = 0;
/**
 * Create visual content blocker for a given node.
 *
 * Returns `undefined` when the container cannot be created.
 */

function createVisual({
  node,
  blocker,
  setVisualParentIfClassOfParent,
  dependantVisibilityContainers,
  mount
}) {
  var _document$querySelect;

  // Short circuit: We do not know a blocker for this; do nothing
  if (!blocker) {
    return undefined;
  } // Apply a unique identifier for this node


  if (!node.hasAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED_PRESERVED)) {
    node.setAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED_PRESERVED, connectedCounterPreserve.toString());
    connectedCounterPreserve++;
  }

  const connectedCounter = +node.getAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED_PRESERVED);
  const {
    parentElement
  } = node;
  const {
    shouldForceToShowVisual = false,
    isVisual,
    id: blockerId
  } = blocker;
  let position = "initial";

  try {
    const computedStyle = window.getComputedStyle(node);
    position = computedStyle.position;
  } catch (e) {// Silence is golden.
  } // Visual content blockers are only allowed for "real" content


  const isPositioned = ["fixed", "absolute", "sticky"].indexOf(position) > -1;
  const isNotInContent = [document.body, document.head, document.querySelector("html")].indexOf(parentElement) > -1;
  const isAlreadyConnected = node.getAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED);
  const [createBefore, visualParentMode] = findVisualParent(node, setVisualParentIfClassOfParent || {}); // const isInlineScriptOrStyle = createBefore.hasAttribute(HTML_ATTRIBUTE_INLINE) || createBefore.hasAttribute(HTML_ATTRIBUTE_INLINE_STYLE);

  const isVisible = !!createBefore.offsetParent; // Always hide elements (again) if no visual blocker is possible (get) and memorize
  // the "!important" state so we can correctly reset it in `probablyResetParentContainerForVisual`

  const fnStrictHide = reason => {
    if (["script", "link"].indexOf(node === null || node === void 0 ? void 0 : node.tagName.toLowerCase()) === -1) {
      if (reason === "qualified" && visualParentMode === "childrenSelector") {
        return;
      }

      const {
        style
      } = node;

      if (style.getPropertyValue("display") === "none" && style.getPropertyPriority("display") === "important") {
        node.setAttribute(HTML_ATTRIBUTE_WAS_PREVIOUSLY_STRICT_HIDDEN, "1");
      } else {
        style.setProperty("display", "none", "important");
      }
    }
  };

  if (isNotInContent || isPositioned && !isPotentialVisualWithinResponsiveContainer(node) && !shouldForceToShowVisual || !isVisual || isAlreadyConnected || !isVisible && !shouldForceToShowVisual) {
    // Check if this content blocker is inside a known container for which we need to wait first its visibility
    if (!isVisible && dependantVisibilityContainers) {
      const dependantContainers = parents(node, dependantVisibilityContainers.join(","));

      if (dependantContainers.length > 0 && !dependantContainers[0].offsetParent) {
        // Wait for visibility, do nothing with this node...
        return undefined;
      }
    }

    fnStrictHide("qualified");
    return undefined;
  } // Avoid duplicate visual blockers and connect them


  const foundDuplicate = findPreviousVisibleDuplicate(createBefore, node, blockerId);

  if (foundDuplicate) {
    node.setAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED, foundDuplicate.getAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED));
    probablyResetParentContainerForVisual(createBefore, true);
    fnStrictHide("duplicate");
    return undefined;
  }

  const {
    container,
    thumbnail
  } = createVisualContainer(node, createBefore, visualParentMode);

  const mountVisualContainer = paintMode => {
    container.setAttribute(HTML_ATTRIBUTE_VISUAL_PAINT_MODE, paintMode);
    mount({
      container,
      blocker,
      connectedCounter,
      onClick: event => {
        // Stop above propagation so click event handlers do not handle this as a click on the
        // mainly blocked element (example: Video inside another container which delegates click event).
        event === null || event === void 0 ? void 0 : event.stopPropagation();
        setLastClickedConnectedCounter(connectedCounter);
      },
      blockedNode: node,
      thumbnail,
      paintMode
    });
    probablyResetParentContainerForVisual(createBefore, true);
  }; // Lazy load the visual container (not depending on view port but do not overload the main thread with idle timing)


  if (isElementInViewport(container, true)) {
    mountVisualContainer("instantInViewport");
  } else if ( // If the previous one was in viewport, consider the next one as "instant" as it could lead
  // to unexpected behavior (e.g. ratio class is used and container is in viewport at the bottom screen
  // but isn't technically due to vertical alignment to bottom).
  ((_document$querySelect = document.querySelector(".rcb-content-blocker[".concat(HTML_ATTRIBUTE_BLOCKER_CONNECTED, "=\"").concat(connectedCounter - 1, "\"][").concat(HTML_ATTRIBUTE_VISUAL_PAINT_MODE, "]"))) === null || _document$querySelect === void 0 ? void 0 : _document$querySelect.getAttribute(HTML_ATTRIBUTE_VISUAL_PAINT_MODE)) === "instantInViewport") {
    mountVisualContainer("instant");
  } else if (window.requestIdleCallback) {
    window.requestIdleCallback(() => mountVisualContainer("idleCallback"));
  } else {
    setTimeout(() => mountVisualContainer("instant"));
  }

  return container;
}

function getLastClickedConnectedCounter() {
  return lastClickedConnectedCounter;
}

function setLastClickedConnectedCounter(connectedCounter) {
  lastClickedConnectedCounter = connectedCounter;
}

export { createVisual, getLastClickedConnectedCounter, setLastClickedConnectedCounter };